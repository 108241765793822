<template>
    <section id="content">
        <div class="container">
            
            <div class="card" id="profile-main">
                <div class="pm-overview c-overflow">
                    <div class="pmo-pic">
                        <div class="p-relative">
                            <VueLoadImage class="img-responsive">
                                <img slot="image" :src="staff.photo != '' ? staff.photo : 'img/placeholder.png'"/>
                                <img slot="preloader" src="img/loading.gif"/>
                                <img slot="error" src="img/placeholder.png"/>
                            </VueLoadImage>
                        </div>
                    </div>
                    <div class="p-b-20 p-l-20 p-r-20">
                        <button class="btn btn-warning btn-block btn-icon-text" @click="editStaff">
                            <i class="zmdi zmdi-edit"></i>
                            Edit Staff
                        </button>
                    </div>
                </div>

                <div class="pm-body clearfix">

                    <!-- Basic -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-account m-r-5"></i> Basic Information</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <dl class="dl-horizontal">
                                    <dt>Full Name</dt>
                                    <dd>{{ staff.fullname }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Gender</dt>
                                    <dd>{{ staff.gender }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Birthday</dt>
                                    <dd>{{ staff.birthday }} <strong>({{ staff.age }})</strong> </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Martial Status</dt>
                                    <dd>{{ staff.marital_status }}</dd>
                                </dl>
                                <dl class="dl-horizontal" v-if="staff.marital_status == 'Married'">
                                    <dt>Name of Spouse</dt>
                                    <dd>{{ staff.spouse }}</dd>
                                </dl>
                                <dl class="dl-horizontal" v-if="staff.marital_status == 'Married'">
                                    <dt>Marriage Anniversary</dt>
                                    <dd>{{ staff.marriage_anniversary }} <strong>({{ staff.marriedFor }})</strong> </dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- contact -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-phone m-r-5"></i> Contact Information</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <dl class="dl-horizontal">
                                    <dt>Mobile Phone 1</dt>
                                    <dd>{{ staff.telephone_1 }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Mobile Phone 2</dt>
                                    <dd>{{ staff.telephone_2 }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Email Address 1</dt>
                                    <dd>{{ staff.email_1 }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Email Address 2</dt>
                                    <dd>{{ staff.email_2 }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- Employment -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-phone m-r-5"></i> Employment Information</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <dl class="dl-horizontal">
                                    <dt>Category</dt>
                                    <dd>{{ staff.category }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Assignment</dt>
                                    <dd>{{ staff.assignment }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Region of Assignment</dt>
                                    <dd>{{ staff.region_assigned }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>City of Assignment</dt>
                                    <dd>{{ staff.city_assigned }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Date Joined</dt>
                                    <dd>{{ staff.dateJoined }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>SSNIT Number</dt>
                                    <dd>{{ staff.ssnit_number }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- Identification -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-phone m-r-5"></i> Identification Information</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <dl class="dl-horizontal">
                                    <dt>Current Passport</dt>
                                    <dd>{{ staff.passport_expiry + ' : ' + staff.passport_number }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Ghana card</dt>
                                    <dd>{{ staff.ghana_card_expiry + ' : ' + staff.ghana_card_number }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Voters ID</dt>
                                    <dd>{{ staff.voters_id_expiry + ' : ' + staff.voters_id_number }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Health Insurance No</dt>
                                    <dd>{{ staff.health_insurance_expiry + ' : ' + staff.health_insurance_number }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- roles -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-city-alt m-r-5"></i>Leadership Roles Held</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view m-b-30" v-for="(role, i) in roles" :key="i">
                                <dl class="dl-horizontal">
                                    <dt>Role</dt>
                                    <dd>{{ role.role }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Started</dt>
                                    <dd>{{ role.started }} {{ role.isCurrent == 1 ? ' - present' : '' }} </dd>
                                </dl>
                                <dl class="dl-horizontal" v-if="role.isCurrent == 0">
                                    <dt>Ended</dt>
                                    <dd>{{ role.ended }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- children -->
                    <div class="pmb-block" v-if="children.length > 0">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-accounts m-r-5"></i> Children Information</h2>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view m-b-30" v-for="(child, index) in children" :key="index">
                                <dl class="dl-horizontal">
                                    <dt>Name</dt>
                                    <dd>{{ child.name }}</dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Date</dt>
                                    <dd>{{ child.dob }} <strong>({{ child.age }})</strong> </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt>Gender</dt>
                                    <dd>{{ child.gender }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- Notes -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-assignment m-r-5"></i> General Notes </h2>
                            <div class="actions">
                                <button type="button" class="btn btn-icon-text" :class="toggleOpenClass" @click="isOpen = !isOpen">
                                    <i class="zmdi zmdi-plus"></i>
                                    {{ isOpen ? 'Close' : 'Add' }}
                                </button>
                            </div>
                        </div>
                        <div class="form-group" v-if="isOpen">
                            <form @submit.prevent="addNote">
                                <textarea class="form-control m-b-10" v-model="note"></textarea>
                                <button type="submit" class="btn btn-success btn-sm btn-icon-text" :disabled="isProcessing">
                                    <i class="zmdi zmdi-save"></i>
                                    Save Note
                                    <span class="loader" v-if="isProcessing"></span>
                                </button>
                            </form>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <ul class="staff-notes" v-if="notes.length > 0">
                                    <li v-for="note in notes" :key="note.id">
                                        <small> {{ note.date }} <em>by:</em>  {{ note.user }} </small>
                                        <div>{{ note.note }}</div>
                                    </li>
                                </ul>
                                <div v-else>No notes added</div>
                            </div>
                        </div>
                    </div>

                    <!-- Files -->
                    <div class="pmb-block">
                        <div class="pmbb-header">
                            <h2><i class="zmdi zmdi-assignment m-r-5"></i> Upload Files</h2>
                            <div class="actions">
                                <button type="button" 
                                    @click="addFiles"
                                    class="btn btn-icon-text btn-primary" >
                                    <i class="zmdi zmdi-cloud-upload"></i>
                                    Upload File
                                </button>
                            </div>
                        </div>
                        <div class="pmbb-body p-l-30">
                            <div class="pmbb-view">
                                <input type="file" ref="files" class="fileupload" @change="handleFilesUpload" multiple>
                                
                                <img src="img/loading.gif" v-if="isSubmitiles">

                                <ul class="filelist" :class="isSubmitiles ? 'loading' : ''">
                                    <li v-for="(file, key) in files" :key="key">
                                        <div class="fileitem">
                                            <div class="filecontent">
                                                <label :for="'caption' + key">{{ file.name }}</label>
                                                <input type="text" :id="'caption' + key" class="filecaption" v-model="file.caption">
                                                <button 
                                                    @click="removeFile(key)"
                                                    type="button" 
                                                    class="btn btn-icon-text btn-danger btn-sm">
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <button 
                                            @click="submitFiles"
                                            v-if="files.length > 0"
                                            type="button" 
                                            class="btn btn-icon-text btn-success m-t-50">
                                            <i class="zmdi zmdi-save"></i>
                                            Submit Files
                                        </button>
                                    </li>
                                </ul>

                                <ul class="staff-files">
                                    <li v-for="(file, k) in staffFiles" :key="k">
                                        <i class="zmdi zmdi-file"></i>
                                        <span>{{ file.caption != '' ? file.caption : 'No Caption' }}</span>
                                        <a :href="file.url" target="_blank">View</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueLoadImage from 'vue-load-image'
export default {
    data() {
        return {
            staffId: this.$route.params.staffId,
            staff: {},
            roles: [],
            children: [],
            notes: [],
            note: "",
            files: [],
            urls: [],
            isProcessing: false,
            isOpen: false,
            isSubmitiles: false,
            staffFiles: [],
        }
    },
    created(){
        this.getStaff()
        this.getFiles()
    },
    methods: {
        editStaff(){
            this.$router.push("/staff/" + this.staffId + "/edit")
        },
        getStaff(){
            this.$http.get("/staff/view", {
                params: {
                    staffId: this.staffId
                }
            }).then(response => {
                this.staff = response.data.staff
                this.children = response.data.children
                this.roles = response.data.roles
                this.notes = response.data.notes
            })
        },
        addNote(){
            this.isProcessing = true
            this.$http.post("/staff/addnote", {
                note: this.note,
                staffId: this.staffId
            }).then(response => {
                this.notes.unshift(response.data.data)
                this.isProcessing = false
                this.isOpen = false
                this.note = ''
            })
        },
        addFiles(){
            this.$refs.files.click()
        },
        handleFilesUpload(){
            let uploadedFiles = this.$refs.files.files
            for( var i = 0; i < uploadedFiles.length; i++ ){
                this.files.push( uploadedFiles[i] )
                this.urls.push( URL.createObjectURL(uploadedFiles[i]) )    
            }
            // console.log(this.files)
        },
        removeFile( key ){
            this.files.splice( key, 1 );
        },

        getFiles(){
            this.$http.get('/staff/files', {
                params: {
                    staffId: this.staffId
                }
            }).then(res => {
                this.staffFiles = res.data
            })
        },

        submitFiles(){ 

            this.isSubmitiles = true
            
            let formData = new FormData()

            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i]
                formData.append('files[' + i + ']', file)
                formData.append('captions[' + i + ']', file.caption)
                formData.append('staffId', this.staffId)
            }

            this.$http.post( '/staff/uploadfiles',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                if (response.data.code == 200) {
                    this.getFiles()
                    this.isSubmitiles = false
                    this.files = []
                    this.urls = []
                }
            })
            .catch(() => {
                this.isSubmitiles = false
            })
        },
    
    
    },
    computed: {
        toggleOpenClass(){
            return this.isOpen == true ? 'btn-danger' : 'btn-primary'
        },
        uploadTarget(){
            return (location.hostname == "localhost" || location.hostname == "127.0.0.1") ? 'http://localhost/gcmgstaff/v1/api/staff/uploadfiles' : 'https://gcmgstaffapi.herokuapp.com/v1/api/staff/uploadfiles'
        }
    },
    components: {
        VueLoadImage,
    },
}
</script>

<style scoped>
.pmbb-view {
    border-bottom: 1px solid #eaeaea;
}
.pmbb-view:last-child {
    border-bottom: 0;
}
dl:last-child {
    margin-bottom: 30px;
}

ul.staff-notes {
    position: relative;
    margin: 0;
    padding: 0;
}
ul.staff-notes li {
    margin-bottom: 20px;
    list-style-type: none;
}
ul.staff-notes li small {
    color: gray;
}
ul.staff-notes li div {
    font-size: 14px;
    color: #444444;
}
.fileupload {
    opacity: 0;
    position: absolute;
    top: -100px;
}
ul.filelist {
    position: relative;
    margin: 0;
    list-style-type: none;
    padding: 0;
}
ul.filelist li {
    position: relative;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    clear: both;
}
ul.filelist li::after {
    clear: right;
}
ul.filelist li span {
    text-align: left;
}
ul.filelist li button::after {
    clear: right;
}
.fileitem {
    display: flex;
}

.filecontent * {
    display: block;
    margin-bottom: 5px;
}

.loading {
    opacity: 0.2;
}

.staff-files {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.staff-files li {
    margin: 0 0 10px;
    font-size: 14px;
}
.staff-files li i {
    margin: 0 10px 0 0;
}
.staff-files li a {
    position: relative;
    right: -20px;
}
</style>